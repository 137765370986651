import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Methods, PostMethods, PutMethods } from '@methods/methods';
import { User } from '@models/auth-model';
import { ResponseData } from '@models/models';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '@services/api.service';
import { NotificationService } from '@services/notification.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html'
})
export class UserFormComponent implements OnInit{
  @Input() userSelected: any;
  @Input() profileList: any;
  @Input() UserTypeList: any;
  @Input() departmentList: any;
  @Input() tabSelected: any;
  @Output() onLoadPayloadUsers = new EventEmitter<any>();

  userLogin: User;
  newUserForm: FormGroup;
  submitted: boolean = false;
  hidePassword: boolean = true;
  errors: { errorName: boolean; errorEmail: boolean; errorNickname: boolean } = {
    errorName: false,
    errorEmail: false,
    errorNickname: false
  };

  constructor(private _translateService: TranslateService, private _apiService: ApiService, private _notificationService: NotificationService, private _formBuilder: FormBuilder){}

  ngOnInit(): void {
    this.newUserForm = this._formBuilder.group({
      userName: [ this.tabSelected == 0? this.userSelected.name : '', [Validators.required]],
      nickName: [ this.tabSelected == 0? this.userSelected.nickname : '', [Validators.required]],
      email: [ this.tabSelected == 0? this.userSelected.email : '', [Validators.required,Validators.email]],
      password : [ this.tabSelected == 0? this.userSelected.password : '', [Validators.required]],
      profile : [ this.tabSelected == 0? this.userSelected.profiles : '', [Validators.required]],
      userType : [ this.tabSelected == 0? this.userSelected.idUserType : '', [Validators.required]],
      departments : [ this.tabSelected == 0? this.userSelected.idDepartment : '', [Validators.required]]
    });
    this.userLogin = JSON.parse(localStorage.getItem('UserAccessCTS')!);
  }

  get f() { return this.newUserForm.controls; }


  searchErrors(errorString: string): void {
    this.errors.errorName = /\bname\b/i.test(errorString);
    this.errors.errorEmail = /\bemail\b/i.test(errorString);
    this.errors.errorNickname = /\bnickname\b/i.test(errorString);
  }

  submitForm(){
    this.submitted = true;

    if (this.newUserForm.invalid) {
      return;
    }
    const data = this.returnObjectData(this.tabSelected);
    const URLPARAMS =  this.returnData(this.tabSelected);
    const URL = `${environment.WebApiUrl + URLPARAMS }`;
    if(this.tabSelected == 1){
        this._apiService.apiPostMethod(URL, data).subscribe({
            next: (result: ResponseData) => {
              if (result.success) {
                if(this.tabSelected == 1){
                  this.newUserForm.reset();
                  this.onLoadPayloadUsers.emit(true)
                }
                this._notificationService.success('GENERICS.ALERT_SUCESS', 'bg-success', 'animate__backInUp', 6000);
              }else{
                this.searchErrors(result.error);
                this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
              }
            },
            error: (err: any) => {
              console.log(err);
              this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
            }
          });
    }else{
        this._apiService.apiPostMethod(URL, data).subscribe({
            next: (result: ResponseData) => {
              if (result.success) {
                this.onLoadPayloadUsers.emit(true)
                this._notificationService.success('GENERICS.ALERT_SUCESS', 'bg-success', 'animate__backInUp', 6000);
              }else{
                this.searchErrors(result.error);
                this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
              }
            },
            error: (err: any) => {
              console.log(err);
              this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
            }
          });
    }
  }

  returnObjectData(idType: number){
    switch (idType) {
        //Edtar usuario
        case 0: return {
            idDepartment : this.f.departments.value,
            idProfile : this.f.profile.value,
            idUserType : this.f.userType.value,
            email : this.f.email.value,
            name : this.f.userName.value,
            nickname : this.f.nickName.value,
            password : this.f.password.value,
            idUserInCharge : this.userLogin.idUser,
            isActive: this.userSelected.isActive,
            accessAllSites: this.userSelected.accessAllSites,
            idUser: this.userSelected.idUser,
            tipoUpdate: 0
        };

        //Nuevo usuario
        case 1: return {
            idDepartment: this.f.departments.value,
            name: this.f.userName.value,
            nickname: this.f.nickName.value,
            email: this.f.email.value,
            password : this.f.password.value,
            idProfile : this.f.profile.value,
            idUserType : this.f.userType.value,
            idUserInCharge: this.userLogin.idUser
        };

        default: return null;
    }
  }

  returnData(idType: number){
    switch (idType) {
        case 0: return PutMethods.UPDATE_USER_INFO;
        case 1: return PostMethods.POST_INSERT_NEW_USER;

        default: return null;
    }
  }

  showPassword(event: boolean){
    this.hidePassword = !event;
  }

  TranslateText(text: string) {
    return this._translateService.instant(text);
  }

}
