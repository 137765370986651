import { Component, ViewChild } from '@angular/core';
import { FilterLogs, FilterLogsBA007, Filters } from '@methods/constants';
import { GetMethods, Methods, getStatusType, transformMediumDate } from '@methods/methods';
import { User } from '@models/auth-model';
import { ResponseData } from '@models/models';
import { ApiService } from '@services/api.service';
import { NotificationService } from '@services/notification.service';
import { ThemeService } from '@services/theme.service';
import { PrimeNGConfig } from 'primeng/api';
import { Table } from 'primeng/table';
import { environment } from 'src/environments/environment';

interface Column {
  field: string;
  header: string;
}
@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html'
})
export class LogsComponent {
  payloadData!: any;
  loading: boolean = true;
  userLogin: User;
  cols!: Column[];
  FilterLogs: Filters[];
  FilterLogsBA007: Filters[];
  showHardReloadBtn: boolean = false;
  domainInfo:any;
  date1: Date;
  date2: any = new Date();
  currentDay: any = new Date();
  @ViewChild('dt') table: Table;
  FilterType: string = 'TicketLog';
  isDarkTheme: boolean;

  constructor(private _apiService: ApiService, private primengConfig: PrimeNGConfig, private _notificationService: NotificationService, private _themeService: ThemeService) {
    this.FilterLogs = FilterLogs;
   }

  ngOnInit() {
    this.primengConfig.ripple = true;
    this.userLogin = JSON.parse(localStorage.getItem('UserAccessCTS')!);
    this.domainInfo =  JSON.parse(localStorage.getItem('DomainCTS')!);
    this.isDarkTheme = this._themeService.getThemeSelected() == 'theme-default-dark' ? true : false;
    this.getFilterTypeDa(this.FilterType);
    if(this.domainInfo.idSite == 992){
        this.FilterLogs = FilterLogsBA007;
    }
  }

  getFilterTypeDa(filter: string ) {

    const data = {
      endDate: this.date2,
      idSite: this.domainInfo.idSite,
      startDate: this.date1,
      typeRequest: filter
    }

    this.cols = [];
    this.cols = this.returnHeaderTableFilter(this.FilterType);
    const URL = `${ environment.WebApiUrl + this.returURLParamenter(this.FilterType) }`;
    this._apiService.apiGetMethod(URL).subscribe({
      next: (result: ResponseData) => {
        if(result.success){
            this.payloadData = result.data;
        }else{
            this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
        }
        this.loading = false;
      },
      error: (err: any) => {
        this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
        this.loading = false;
        this.showHardReloadBtn = true;
        console.log(err);
      }
    });
  }

  returURLParamenter(filterType: string) {

    if(this.date1 == null){
        this.date1 = new Date();
        this.date1.setDate(this.date1.getDate() - 4)
    }
    switch(filterType){
        case 'TicketLog' : return GetMethods.GET_TICKET_LOGS + transformMediumDate(this.date1) + '&endDate=' + transformMediumDate(this.date2 != null ? this.date2 : new Date()) + '&IdSite=' + this.domainInfo.idSite;

        case 'userLog' : return GetMethods.GET_USER_LOGS + transformMediumDate(this.date1) + '&endDate=' + transformMediumDate(this.date2 != null ? this.date2 : new Date());

        case 'SubjectLog' : return GetMethods.GET_SUBJECT_LOGS + transformMediumDate(this.date1) + '&endDate=' + transformMediumDate(this.date2 != null ? this.date2 : new Date());

        case 'SubjectFieldsLog' : return GetMethods.GET_SUBJECT_FIELDS_LOGS + transformMediumDate(this.date1) + '&endDate=' + transformMediumDate(this.date2 != null ? this.date2 : new Date());

        case 'RelevanceLog' : return GetMethods.GET_RELEVANCE_LOGS + transformMediumDate(this.date1) + '&endDate=' + transformMediumDate(this.date2 != null ? this.date2 : new Date());

        case 'StatusLog' : return GetMethods.GET_STATUS_LOGS;

        case 'DepartmentLog' : return GetMethods.GET_DEPARTMENT_LOGS + transformMediumDate(this.date1) + '&endDate=' + transformMediumDate(this.date2 != null ? this.date2 : new Date());

        default: return null;
    }
  }

  returnHeaderTableFilter(filterType: string){
    switch (filterType) {
      case 'TicketLog' : return [
        { field: 'ticket', header: 'Ticket' },
        { field: 'action', header: 'logs.Action' },
        { field: 'lastmodification', header: 'logs.Last Modification' },
        { field: 'responsible', header: 'logs.Responsible' },
        { field: 'details', header: 'logs.Details' }
      ]

      case 'userLog' : return [
        { field: 'user', header: 'Users' },
        { field: 'action', header: 'logs.Action' },
        { field: 'lastmodification', header: 'logs.Last Modification' },
        { field: 'responsible', header: 'logs.Responsible' },
        { field: 'details', header: 'logs.Details' }
      ]

      case 'SubjectLog' : return [
        { field: 'editedSubject', header: 'Edited Subject' },
        { field: 'action', header: 'logs.Action' },
        { field: 'lastmodification', header: 'logs.Last Modification' },
        { field: 'responsible', header: 'logs.Responsible' },
        { field: 'details', header: 'logs.Details' }
      ]

      case 'SubjectFieldsLog' : return [
        { field: 'subject', header: 'Subject' },
        { field: 'field', header: 'Field' },
        { field: 'action', header: 'logs.Action' },
        { field: 'lastmodification', header: 'logs.Last Modification' },
        { field: 'responsible', header: 'logs.Responsible' },
        { field: 'details', header: 'logs.Details' }
      ]

      case 'RelevanceLog' : return [
        { field: 'relevance', header: 'Relevance' },
        { field: 'action', header: 'logs.Action' },
        { field: 'lastmodification', header: 'logs.Last Modification' },
        { field: 'responsible', header: 'logs.Responsible' },
        { field: 'details', header: 'logs.Details' }
      ]

      case 'StatusLog' : return [
        { field: 'ticket', header: 'Ticket' },
        { field: 'action', header: 'logs.Action' },
        { field: 'lastmodification', header: 'logs.Last Modification' },
        { field: 'responsible', header: 'logs.Responsible' },
        { field: 'details', header: 'logs.Details' }
      ]

      case 'DepartmentLog' : return [
        { field: 'department', header: 'Department' },
        { field: 'action', header: 'logs.Action' },
        { field: 'lastmodification', header: 'logs.Last Modification' },
        { field: 'responsible', header: 'logs.Responsible' },
        { field: 'details', header: 'logs.Details' }
      ]
      default: return [];
    }
  }

  onFilterChange(event:any){
    this.FilterType = event.value;
    this.getFilterTypeDa(this.FilterType);
  }

  DateFilter(){
    this.getFilterTypeDa(this.FilterType);
  }

  getStatusType(status:string){
    return getStatusType(status, this.isDarkTheme)
  }

  hardRefresh(){
    location.reload();
  }
}
