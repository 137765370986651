<p-card *ngIf="!showExtraInfo" class="card" header="{{ title | translate}}" subheader="" [style]="{ width: 'auto', padding: '0' }">
    <p-tabView [scrollable]="Media.IsMobile" (onChange)="getTabSelected($event.index)" id="closableTabView">
        <p-tabPanel headerStyleClass="text-uppercase" *ngFor="let item of tabPanelData" header="{{item.tabTitle | translate}}" [selected]="false">
            <p-table *ngIf="!showNewFormEntry" #dt
                [value]="payloadData"
                [columns]="columsData"
                [scrollable]="true"
                scrollHeight="700px"
                dataKey="id"
                styleClass="p-datatable-striped"
                [rowHover]="true"
                [rows]="20"
                [rowsPerPageOptions]="[20,40,60]"
                [showCurrentPageReport]="true"
                [loading]="loading"
                [paginator]="true"
                responsiveLayout="stack"
                [tableStyle]="{'min-width': '80rem'}"
                currentPageReportTemplate="{{'Showing {first} to {last} of {totalRecords} entries' | translate}}"
                [filterDelay]="0"
                [globalFilterFields]="['emailSubject', 'Description', 'idShurtcut', 'UserName', 'name', 'label', 'date', 'idTicket']">

                <ng-template pTemplate="caption">
                    <div class="row g-2">
                        <div class="col-12 col-sm">
                            <input pInputText type="text" (input)="dt.filterGlobal($any($event.target) .value, 'contains')" placeholder="{{ 'Search' | translate }}" class="p-column-filter w-100">
                        </div>

                        <div *ngIf="identity == 2" class="col-12 col-sm">
                            <p-dropdown [options]="departmentList" (onChange)="dt.filter($event.value, 'departmentName', 'equals')" styleClass="p-column-filter d-flex w-100"
                                placeholder="{{'Department' | translate}}" [showClear]="true">
                                <ng-template let-option pTemplate="item">
                                    <span [class]="'customer-badge status-' + option.value">{{option.label}}</span>
                                </ng-template>
                            </p-dropdown>
                        </div>

                        <div class="col-12 col-sm">
                            <p-dropdown [options]="userList" (onChange)="dt.filter($event.value, 'label', 'equals')"
                                styleClass="p-column-filter d-flex w-100"
                                placeholder="{{'UserName' | translate}}"
                                [showClear]="true" [filter]="true" filterBy="name">
                                <ng-template let-option pTemplate="item">
                                    <span [class]="'customer-badge status-' + option.value">{{option.name}}</span>
                                </ng-template>
                            </p-dropdown>
                        </div>
                    </div>
                </ng-template>

                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th class="text-uppercase" *ngFor="let col of columns">
                            {{ col.header | translate }}
                        </th>
                    </tr>
                </ng-template>

                <ng-template pTemplate="body" let-payloadData>
                    <tr *ngIf="identity === 1" class="p-selectable-row">
                        <td [ngClass]="getStatusType(payloadData.status)">{{payloadData.idTicket}}</td>
                        <td [ngClass]="getStatusType(payloadData.status)">{{payloadData.date | date:'M/d/yy'}}</td>
                        <td [ngClass]="getStatusType(payloadData.status)">{{payloadData.emailSubject | translate}}</td>
                        <td [ngClass]="getStatusType(payloadData.status)">{{payloadData.tickerOrTaskName | translate}}</td>
                        <td [ngClass]="getStatusType(payloadData.status)">{{payloadData.label}}</td>
                        <td [ngClass]="getStatusType(payloadData.status)">{{payloadData.status | translate}}</td>
                        <td>
                            <button class="btn btn-icon" [ngClass]="{'p-1': Media.IsMobile}" (click)="editItem(payloadData)" type="button">
                                <i class="feather icon-eye"></i>
                            </button>
                            <button class="btn btn-icon" [ngClass]="{'p-1': Media.IsMobile}" (click)="deleteItem(payloadData)"  type="button">
                                <i class="feather icon-trash-2"></i>
                            </button>
                        </td>
                    </tr>
                    <tr *ngIf="identity === 2" class="p-selectable-row">
                        <td>{{payloadData.idShortcut}}</td>
                        <td>{{payloadData.name}}</td>
                        <td>{{payloadData.description | translate}}</td>
                        <td>{{payloadData.departmentName | translate}}</td>
                        <td>{{payloadData.dateCreated | date:'M/d/yy, h:mm a'}}</td>
                        <td>{{payloadData.label | translate}}</td>
                        <td>
                            <button class="btn btn-icon" [ngClass]="{'p-1': Media.IsMobile}" (click)="editItem(payloadData)" type="button">
                                <i class="feather icon-eye"></i>
                            </button>
                            <button class="btn btn-icon" [ngClass]="{'p-1': Media.IsMobile}" (click)="deleteItem(payloadData)"  type="button">
                                <i class="feather icon-trash-2"></i>
                            </button>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td style="padding: 200px; text-align: center;" colspan="12">
                            <span *ngIf="!showHardReloadBtn"> {{ 'GENERICS.NO_DATA_FOUND' | translate }} </span>
                            <span *ngIf="showHardReloadBtn"> {{'GENERICS.UPDATED_ERROR' | translate}} <a class="btn btn-danger" (click)="hardRefresh()"> <i class="feather icon-refresh-cw"></i> {{ 'Refresh' | translate }}</a> </span>
                        </td>
                    </tr>
                </ng-template>
            </p-table>

            <ng-container *ngIf="showNewFormEntry">
                <app-ticket-form
                    [formType]="formType"
                    [formTicket]="formTicket"
                    [departmentList]="departmentList"
                    [statusList]="statusList"
                    (onCallFormMethod)="onCallFormMethod($event)">
                </app-ticket-form>
            </ng-container>
        </p-tabPanel>
    </p-tabView>
</p-card>

<div *ngIf="showExtraInfo" class="stickty-button">
    <p-button (onClick)="getTabSelected(0);" pTooltip="Back"  styleClass="p-button-primary ms-2" icon="feather icon-arrow-left" label="Back"></p-button>
</div>

<p-card *ngIf="showExtraInfo" class="card custom-top-margin" header="{{editItemSelected.title | translate}} - {{ '  #' + editItemSelected.idTicket}}" subheader="" [style]="{ width: 'auto', padding: '0' }">
    <div *ngIf="showExtraInfo" class="my-3">
        <button ngbTooltip="{{ !showExtraInfoForm ? TranslateText('Edit'): TranslateText('Cancel') }}" type="button" (click)="editTaskForm()" class="btn btn-secondary custom-card-buttom">
            <i class="feather" [ngClass]="{'icon-edit': !showExtraInfoForm, 'icon-x' : showExtraInfoForm}"></i>
            <span class="ms-2">{{ !showExtraInfoForm ? TranslateText('Edit'): TranslateText('Cancel')}}</span>
        </button>
    </div>
    <ng-container>
        <div *ngIf="!showExtraInfoForm" class="row d-flex align-items-start justify-content-center">
            <div class="col-12 col-sm-6">
                <div class="shadow p-3 mb-3 bg-body rounded">
                    <h5 class="badge bg-info">{{'DateCreated' | translate}} </h5>
                    <div class="d-flex align-items-center">
                        <span>{{editItemSelected.date | date:'M/d/yy, h:mm a'}}</span>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-6">
                <div class="shadow p-3 mb-3 bg-body rounded">
                    <h5 class="badge bg-info">{{'CreatedBy' | translate}}</h5>
                    <div class="d-flex align-items-center">
                        <span>{{editItemSelected.label | translate}} </span>
                    </div>
                </div>
            </div>

            <div class="col-12 col-sm-6">
                <div class="shadow p-3 mb-3 bg-body rounded">
                    <h5 class="badge bg-info">{{'Department' | translate}} </h5>
                    <div class="d-flex align-items-center">
                        <span>{{editItemSelected.departmentName | translate }}</span>
                    </div>
                </div>
            </div>

            <div *ngIf="identity == 1" class="col-12 col-sm-6">
                <div class="shadow p-3 mb-3 bg-body rounded">
                    <h5 class="badge bg-info">{{'Status' | translate}}</h5>
                    <div class="d-flex align-items-center">
                        <span>{{editItemSelected.status | translate}}</span>
                    </div>
                </div>
            </div>

            <div *ngIf="identity == 2" class="col-12 col-sm-6">
                <div class="shadow p-3 mb-3 bg-body rounded">
                    <h5 class="badge bg-info">{{'Name' | translate}}</h5>
                    <div class="d-flex align-items-center">
                        <span>{{editItemSelected.name}}</span>
                    </div>
                </div>
            </div>

            <div *ngIf="identity == 1" class="col-12 col-sm-6">
                <div class="shadow p-3 mb-3 bg-body rounded">
                    <h5 class="badge bg-info">{{'EmailSubject' | translate}} </h5>
                    <div class="d-flex align-items-center">
                        <span>{{editItemSelected.emailSubject | translate }}</span>
                    </div>
                </div>
            </div>

            <div *ngIf="identity == 2" class="col-12 col-sm-6">
                <div class="shadow p-3 mb-3 bg-body rounded">
                    <h5 class="badge bg-info">{{'Description' | translate}} </h5>
                    <div class="d-flex align-items-center">
                        <span>{{editItemSelected.description | translate}}</span>
                    </div>
                </div>
            </div>
            <div *ngIf="identity == 1" class="col-12 col-sm-6">
                <div class="shadow p-3 mb-3 bg-body rounded">
                    <h5 class="badge bg-info">{{'Ticket' | translate}}</h5>
                    <div class="d-flex align-items-center">
                        <span>{{editItemSelected.tickerOrTaskName | translate}}</span>
                    </div>
                </div>
            </div>

            <div *ngIf="identity == 2" class="col-12 col-sm-6">
                <div class="shadow p-3 mb-3 bg-body rounded">
                    <h5 class="badge bg-info">{{'Message' | translate}} </h5>
                    <div class="d-flex align-items-center">
                        <span [innerHTML]="editItemSelected.message"></span>
                    </div>
                </div>
            </div>
        </div>
       <ng-container *ngIf="showExtraInfoForm">
            <app-ticket-form
                [formType]="(identity == 1) ? 10 : 13"
                [formTicket]="formTicket"
                [departmentList]="departmentList"
                [statusList]="statusList"
                [editItemSelected]="editItemSelected"
                (onCallFormMethod)="onCallFormMethod($event)">
            </app-ticket-form>
       </ng-container>

    </ng-container>
</p-card>

<p-card *ngIf="showCommentList" class="card mt-3" header="{{'Comments' | translate}}" subheader="" [style]="{ width: 'auto', padding: '0' }">
    <div class="row hovered py-5 reply-player-{{col.SendToPlayer}}" *ngFor="let col of commentList">
        <div class="col-sm-auto">
          <img width="50px" height="50px" src="assets/images/userProfile.jpg" alt="user image" class="rounded-circle">
        </div>

        <div class="col">
            <h6 class="">
                <b>{{col.sender }}</b>
                <span class="float-end f-13 text-muted text-small">
                    <b>{{'Date' | translate}}</b>
                    {{col.date| date:'short' }}
                </span>
            </h6>
          <hr style="border-color: #8CDDCD;">
          <div [innerHTML]="transform(col.comment)"></div>
        </div>
    </div>
    <div class="border-top pt-4">
        <button ngbTooltip="{{ !showNewComment ? TranslateText('AddComment'): TranslateText('Cancel') }}" type="button" (click)="addNewCommentEvent()" class="btn btn-secondary custom-card-buttom">
            <i class="feather" [ngClass]="{'icon-edit': !showNewComment, 'icon-x' : showNewComment}"></i>
            <span class="ms-2">{{ !showNewComment ? TranslateText('AddComment'): TranslateText('Cancel') }}</span>
        </button>
        <ng-container *ngIf="showNewComment">
            <app-ticket-form
                [formType]="9"
                [formTicket]="formTicket"
                [departmentList]="departmentList"
                [statusList]="statusList"
                [editItemSelected]="editItemSelected"
                (onCallFormMethod)="onCallFormMethod($event)">
            </app-ticket-form>
        </ng-container>
    </div>
</p-card>
