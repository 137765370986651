import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Column, Filters, UserTypeList } from '@methods/constants';
import { DeleteMethods, DeleteUserById, GetMethods, Methods, PostMethods, PutMethods } from '@methods/methods';
import { User } from '@models/auth-model';
import { Department, ResponseData, UserBySite } from '@models/models';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '@services/api.service';
import { MediaResponse, MediaService } from '@services/media.service';
import { NotificationService } from '@services/notification.service';
import { PrimeNGConfig } from 'primeng/api';
import { Table } from 'primeng/table';
import { Subscription, filter } from 'rxjs';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
declare const bootstrap: any;

@Component({
    selector: 'app-user',
    templateUrl: './user.component.html'
})
export class UserComponent {
    mediaSubscription: Subscription;
    Media: MediaResponse;
    payloadData!: any;
    loading: boolean = true;
    userLogin: User;
    cols!: Column[];
    showHardReloadBtn: boolean = false;
    checked: boolean = true;
    submitted: boolean = false;
    newUserForm: FormGroup;
    profileList: any;
    UserTypeList: Filters[];
    allSiteTags: Filters[];
    departmentList: any;
    departmentListByUser: any;
    departmentListByIdUser: any;
    listInternalDepartment: Department[] = [];
    showUserInfo: boolean = false;
    modalUserDeparmentList: any;
    listInternalDepartmentModal: any;
    userSelected: any;
    tabOptionSelected: number = 0;
    listsUsersMenu: any;
    listsUserSites: any;
    siteTagSelected: any;
    allChecked: boolean = false;

    @ViewChild('dt') table: Table;

    constructor(
        private _apiService: ApiService,
        private _mediaService: MediaService,
        private primengConfig: PrimeNGConfig,
        private _translateService: TranslateService,
        private _notificationService: NotificationService) {
        this.UserTypeList = UserTypeList;
        this.mediaSubscription = this._mediaService.subscribeMedia().subscribe(media => {
            this.Media = media;
        });
    }

    ngOnInit() {
        this.cols = [
            { field: 'name', header: 'Name', customExportHeader: 'Product Code' },
            { field: 'email', header: 'Email' },
            { field: 'department', header: 'Department' },
            { field: 'profile', header: 'User Profile' },
            { field: 'userType', header: 'User Type' },
            { field: 'active', header: 'Is Active', showFilter: true },
            { field: 'actions', header: 'Actions' }
        ];

        this.primengConfig.ripple = true;
        this.userLogin = JSON.parse(localStorage.getItem('UserAccessCTS')!);
        this.getAllUsersList();
        this.getAllProfilesList();
        this.getAllDepartmentList();
        this.getAllWebsiteList();
    }


    getAllUsersList() {
        this.payloadData = []
        const URL = `${environment.WebApiUrl + GetMethods.GET_ALL_USERS}`;
        this._apiService.apiGetMethod(URL).subscribe({
            next: (result: ResponseData) => {
                if (result.success) {
                    this.payloadData = result.data;
                }
                this.loading = false;
            },
            error: (err: any) => {
                this.loading = false;
                this.showHardReloadBtn = true;
                console.log(err);
            }
        });
    }

    getAllProfilesList() {
        this.payloadData = []
        const URL = `${environment.WebApiUrl + GetMethods.GET_ALL_PROFILES}`;

        this._apiService.apiGetMethod(URL).subscribe({
            next: (result: ResponseData) => {
                if (result.success) {
                    this.profileList = result.data;
                }
            },
            error: (err: any) => {
                this.loading = false;
                console.log(err);
            }
        });
    }

    getAllDepartmentList() {

        this.departmentList = [];
        const URL = `${environment.WebApiUrl + GetMethods.GET_DEPARTMENT_LIST}`;

        this._apiService.apiGetMethod(URL).subscribe({
            next: (result: ResponseData) => {
                if(result.success){
                    result.data.forEach((value: any) => {
                        value.value = value.name;
                        value.label = value.name;

                        if(value.isInternal){
                          const internalDepartment = {
                            email: value.email,
                            idDepartment: value.idDepartment,
                            isActive: value.isActive,
                            isInternal: value.isInternal,
                            name: value.name,
                            userInCharge: value.userInCharge
                          }
                          this.listInternalDepartment.push(internalDepartment);
                        }
                      });
                    this.departmentList = result.data;
                }
            },
            error: (err: any) => {
                this.loading = false;
                console.log(err);
            }
        });
    }

    getAllWebsiteList() {
        const URL = `${environment.WebApiUrl + GetMethods.GET_ALL_SITE_TAGS}`;

        this._apiService.apiGetMethod(URL).subscribe({
            next: (result: ResponseData) => {
                if (result.success) {
                    this.allSiteTags = result.data;
                }
            },
            error: (err: any) => {
                this.loading = false;
                console.log(err);
            }
        });
    }

    getDepartmentByUser(idUser: number) {
        this.departmentListByUser = [];
        const URL = `${environment.WebApiUrl + GetMethods.GET_DEPARMENT_BY_USER + idUser}`;

        this._apiService.apiGetMethod(URL).subscribe({
            next: (result: ResponseData) => {
                if (result.success) {
                    this.departmentListByUser = result.data;
                    this.modalUserDeparmentList = new bootstrap.Modal(document.getElementById('modalUserDeparmentList'), {
                        keyboard: false
                    })
                    this.modalUserDeparmentList.show();
                }
            },
            error: (err: any) => {
                this.loading = false;
                console.log(err);
            }
        });
    }

    getDepartmentListByUser() {
        const URL = `${environment.WebApiUrl + GetMethods.GET_DEPARMENT_BY_USER + this.userSelected.idUser}`;
        this._apiService.apiGetMethod(URL).subscribe({
            next: (result: ResponseData) => {
                if(result.success){
                    if (result.data.length > 0) {
                        this.departmentListByIdUser = result.data;
                        for (let i = 0; i < this.listInternalDepartment.length; i++) {
                            this.listInternalDepartment[i].isActive = false;
                            for (let j = 0; j < this.departmentListByIdUser.length; j++) {
                                if (this.listInternalDepartment[i].idDepartment == this.departmentListByIdUser[j].idDepartment) {
                                    this.listInternalDepartment[i].isActive = true;
                                }
                            }
                        }
                        this.listInternalDepartmentModal = new bootstrap.Modal(document.getElementById('listInternalDepartmentModal'), {
                            keyboard: false
                        })
                        this.listInternalDepartmentModal.show();
                    }
                } else {
                    this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
                }
            },
            error: (err: any) => {
                this.loading = false;
                console.log(err);
                this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
            }
        });
    }

    UpdateTableInfo(event: any, type: number) {
        if (event != null) {
            this.checked = event.checked;
        }
        const data = this.returnObjetData(type);
        const URL = `${environment.WebApiUrl + this.returnUrlParameter(type)}`;

        if(type == 7) {
            this._apiService.apiPostMethod(URL, data).subscribe({
                next: (result: ResponseData) => {
                    if(result.success){
                        if (type == 7) {
                            this.getAllSitesByIdUser(data?.idUser)
                        }
                        this._notificationService.success('GENERICS.ALERT_SUCESS', 'bg-success', 'animate__backInUp', 6000);
                    } else {
                        this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
                    }
                },
                error: (err: any) => {
                    console.log(err);
                    this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
                }
            });
        }else{
            this._apiService.apiPostMethod(URL, data).subscribe({
                next: (result: ResponseData) => {
                    if(result.success){
                        if (type == 4) {
                            if (this.userSelected.idUser === this.userLogin.idUser) {
                                this.userLogin.viewOnly = data?.isActive
                                localStorage.setItem('UserAccessCTS', JSON.stringify(this.userLogin));
                            }
                        }
                        this._notificationService.success('GENERICS.ALERT_SUCESS', 'bg-success', 'animate__backInUp', 6000);
                    } else {
                        this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
                    }
                },
                error: (err: any) => {
                    console.log(err);
                    this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
                }
            });
        }
    }

    returnUrlParameter(idType: number) {
        switch (idType) {
            case 1:
            case 3: return PutMethods.UPDATE_USER_INFO;
            case 4: return PutMethods.UPDATE_VIEW_ONLY_BY_USER;
            case 5: return PutMethods.UPDATE_RELEASE_TICKET_BY_USER;
            case 6: return PutMethods.UPDATE_TFA_SELECTION;
            case 7: return PostMethods.POST_INSERT_NEW_USER_BY_SITE;

            default: return null;
        }
    }

    returnObjetData(idType: number) {
        switch (idType) {

            case 1: return {
                accessAllSites: this.checked,
                idUserInCharge: this.userLogin.idUser,
                countDepartment: this.userSelected.countDepartment,
                departmentName: this.userSelected.departmentName,
                idDepartment: this.userSelected.idDepartment,
                idUser: this.userSelected.idUser,
                isActive: this.userSelected.isActive,
                isDelete: this.userSelected.isDelete,
                profiles: this.userSelected.profiles,
                idProfile: this.userSelected.profiles,
                profileName: this.userSelected.profileName,
                releaseTicket: this.userSelected.releaseTicket,
                smartLogin: this.userSelected.smartLogin,
                userType: this.userSelected.userType,
                userTypeName: this.userSelected.userTypeName,
                viewOnly: this.userSelected.viewOnly,
                email: this.userSelected.email,
                isTFA: this.userSelected.isTFA,
                name: this.userSelected.name,
                nickname: this.userSelected.nickname,
                password: this.userSelected.password,
                token: this.userSelected.token,
            };

            case 3: return {  // Edit Profile
                accessAllSites: this.userSelected.accessAllSites,
                countDepartment: this.userSelected.countDepartment,
                departmentName: this.userSelected.departmentName,
                idDepartment: this.userSelected.idDepartment,
                idUser: this.userSelected.idUser,
                isActive: this.checked,
                isDelete: this.userSelected.isDelete,
                profiles: this.userSelected.profiles,
                idProfile: this.userSelected.profiles,
                profileName: this.userSelected.profileName,
                releaseTicket: this.userSelected.releaseTicket,
                smartLogin: this.userSelected.smartLogin,
                idUserInCharge: this.userLogin.idUser,
                userType: this.userSelected.userType,
                userTypeName: this.userSelected.userTypeName,
                viewOnly: this.userSelected.viewOnly,
                email: this.userSelected.email,
                isTFA: this.userSelected.isTFA,
                name: this.userSelected.name,
                nickname: this.userSelected.nickname,
                password: this.userSelected.password,
                token: this.userSelected.token,
            };

            case 4:
            case 5: return {
                idUser: this.userSelected.idUser,
                isActive: this.checked
            }

            case 6: return {
                idUser: this.userSelected.idUser,
                isTFA: this.checked
            };

            case 7: return {
                idSite: this.siteTagSelected,
                idUser: this.userSelected.idUser,
                isActive: true
            }

            default: return null;
        }
    }

    editUser(user: any) {
        this.showUserInfo = true;
        this.userSelected = user;
        const URL = `${environment.WebApiUrl + GetMethods.GET_USER_COMPONET_USER_ADMIN + user.idUser}`;
        this._apiService.apiGetMethod(URL).subscribe({
            next: (result: ResponseData) => {
                if(result.success){
                    if (result.data.length > 0) {
                        this.listsUsersMenu = result.data;
                        var allChecked = result.data.find(x => x.isActive == false);
                        this.allChecked = (allChecked == null || allChecked == undefined) ? true : false;
                        this.getAllSitesByIdUser(user.idUser)
                    }
                }
            },
            error: (err: any) => {
                console.log(err);
                this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
            }
        });
    }

    getAllSitesByIdUser(idUser: number) {
        const URL = `${environment.WebApiUrl + GetMethods.GET_SITE_BY_USER + idUser}`;
        this._apiService.apiGetMethod(URL).subscribe({
            next: (result: ResponseData) => {
                if(result.success){
                    if (result.data.length > 0) {
                        this.listsUserSites = result.data;
                    }
                }
            },
            error: (err: any) => {
                console.log(err);
                this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
            }
        });
    }

    deleteUser(user: User) {
        Swal.fire({
            text: "Are you sure?",
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'No',
            confirmButtonText: 'Yes',
            allowOutsideClick: false
        }).then((result) => {
            if (result.isConfirmed) {
                const URL = `${environment.WebApiUrl + DeleteMethods.DELETE_USER}`;
                this._apiService.apiPostMethod(URL, user).subscribe({
                    next: (result: ResponseData) => {
                        if (result.success) {
                            //DeleteUserById(this.payloadData, user.idUser)
                            this.getAllUsersList();
                            this.table.reset();
                            this._notificationService.success('GENERICS.ALERT_SUCESS', 'bg-success', 'animate__backInUp', 6000);
                        }
                    },
                    error: (err: any) => {
                        console.log(err);
                        this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
                    }
                });
            }
        })
    }

    deleteSiteForUser(item: UserBySite) {
        const URL = `${environment.WebApiUrl + DeleteMethods.DELETE_SITE_FOR_USER}`;
        this._apiService.apiPostMethod(URL, item).subscribe({
            next: (result: ResponseData) => {
                if (result.success) {
                    const objWithIdIndex = this.listsUserSites.findIndex((obj: any) => obj.idSite === item.idSite);
                    if (objWithIdIndex > -1) {
                        this.listsUserSites.splice(objWithIdIndex, 1);
                    }
                    this._notificationService.success('GENERICS.ALERT_SUCESS', 'bg-success', 'animate__backInUp', 6000);
                }
            },
            error: (err: any) => {
                console.log(err);
                this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
            }
        });
    }

    updateStatus(event: any, item: any) {
        const data = { idComponentsByUsers: 0, idTagComponent: item.idTagComponent, idUser: item.idUser, isActive: event.target.checked, userInCharge: this.userLogin.idUser, all: false };
        const URL = `${environment.WebApiUrl + PutMethods.UPDATE_USER_RIGHTS}`;
        this._apiService.apiPostMethod(URL, data).subscribe({
            next: (result: ResponseData) => {
                if (result.success) {
                    const index = this.listsUsersMenu.findIndex((x) => x.idTagComponent === item.idTagComponent);
                    this.listsUsersMenu[index].isActive = event.target.checked;
                    var allChecked = this.listsUsersMenu.find(x => x.isActive == false);
                    this.allChecked = (allChecked == null || allChecked == undefined) ? true : false;
                    this._notificationService.success('GENERICS.ALERT_SUCESS', 'bg-success', 'animate__backInUp', 6000);
                }
            },
            error: (err: any) => {
                console.log(err);
                this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
            }
        });
    }

    updateDepartmentChecked(event: any, item: any) {
        const data = { idDepartment: item.idDepartment, idUser: this.userSelected.idUser, isActive: event.target.checked, idUserInCharge: this.userLogin.idUser };
        const URL = `${environment.WebApiUrl + PutMethods.UPDATE_DEPARTMENTS_BY_USER}`;
        this._apiService.apiPostMethod(URL, data).subscribe({
            next: (result: ResponseData) => {
                if (result.success) {
                    // const index = this.listsUsersMenu.findIndex((x) => x.IdTagComponent === item.IdTagComponent);
                    // this.listsUsersMenu[index].isActive = event.target.checked;
                    // var allChecked = this.listsUsersMenu.find(x => x.isActive == false);
                    // this.allChecked = ( allChecked == null  || allChecked == undefined) ? true : false;
                    this._notificationService.success('GENERICS.ALERT_SUCESS', 'bg-success', 'animate__backInUp', 6000);
                }else{
                    this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
                }
            },
            error: (err: any) => {
                console.log(err);
                this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
            }
        });
    }

    onLoadPayloadUsers(event: any) {
        if (event) {
            this.getAllUsersList();
        }
    }

    hardRefresh() {
        location.reload();
    }

    tabSelected(tab: number) {
        this.tabOptionSelected = tab;
    }

    TranslateText(text: string) {
        return this._translateService.instant(text);
    }

    onStepChange(showUserInfo: boolean) {
        this.showUserInfo = showUserInfo;
    }
}
