import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { NotificationService } from 'src/app/services/notification.service';
import { LanguageFilter, NAVIGATION } from "src/app/common/constants";
import { ApiService } from '@services/api.service';
import { MediaResponse, MediaService } from '@services/media.service';
import { environment } from 'src/environments/environment';
import { GetMethods, Methods, PutMethods, getSmartLoginTimeOut, transformMediumDate } from '@methods/methods';
import { User } from '@models/auth-model';
import { CookieService } from 'ngx-cookie-service';
import { Filters } from '@methods/constants';
import { ConfigByDomain, ResponseData } from '@models/models';
import { TranslationService } from '@services/translate.service';
import { CounterService } from '@services/counter.service';
import { ThemeService } from '@services/theme.service';
import { TranslateService } from '@ngx-translate/core';

declare const bootstrap: any;

interface Column {
    ticket: string;
    view: string;
}

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html'
})
export class NavbarComponent {
    @Input() isExpanded: boolean;
    private mediaSubscription: Subscription;
    navigation: any = NAVIGATION;
    submitted = false;
    userName: any = '';
    idUser: any;
    Media: MediaResponse;
    generalSearchModal: any;
    userLogin: User;
    domainInfo: ConfigByDomain;
    loading: boolean = false;
    ticketData!: any[];
    ticketDataTemp!: any[];
    cols!: Column[];
    showTable: boolean = false;
    isAccountSelected: number = 0;
    smartLoginForm: FormGroup;
    userActive: string = '';
    messageResult: string = "";
    langFilter: Filters[]
    langItemSelected: any;

    get f() { return this.smartLoginForm.controls; }


    constructor(private _media: MediaService, private _apiService: ApiService, private _formBuilder: FormBuilder, private _cookieService: CookieService,
        private router: Router, private _notificationService: NotificationService, private _translationService: TranslationService, private _translateService: TranslateService,
        private _themeService: ThemeService) {
        this.mediaSubscription = this._media.subscribeMedia().subscribe(media => {
            this.Media = media;
        });

        this.langFilter = LanguageFilter;
    }

    ngOnInit(): void {

        this.getModalInit();
        this.userLogin = JSON.parse(localStorage.getItem('UserAccessCTS')!);
        this.domainInfo = JSON.parse(localStorage.getItem('DomainCTS')!);

        this.cols = [
            { ticket: 'ticket', view: 'Ticket' },
            { ticket: 'view', view: 'View' },
        ];

        this.smartLoginForm = this._formBuilder.group({
            isSmartLogin: ['', [Validators.required]]
        });
        if (this.userLogin) {
            this.getSmartLogin();
            this.getUserPreference();
        }

    }

    toggleSidebar(event) {
        console.log(event);
    }

    getSmartLogin() {
        const URL = `${environment.WebApiUrl + GetMethods.GET_SMART_LOGIN_SELECTION + this.userLogin.idUser}`;

        this._apiService.apiGetMethod(URL).subscribe({
            next: (result: ResponseData) => {
                if (result.success) {
                    //this._cookieService.set('isSmartLogin', result.data.smartLogin)
                    localStorage.setItem('isSmartLogin', result.data.smartLogin);
                    this.setCookiesSession(result.data.smartLogin);
                    this.smartLoginForm = this._formBuilder.group({
                        isSmartLogin: [result.data.smartLogin, [Validators.required]]
                    });
                }
            },
            error: (err: any) => {
                console.log(err);
            }
        });
    }

    getUserPreference() {
        const URL = `${environment.WebApiUrl + GetMethods.GET_USER_PREFERENCE + this.userLogin.idUser + '&IdSite=' + this.domainInfo.idSite}`;
        this._apiService.apiGetMethod(URL).subscribe({
            next: (result: ResponseData) => {
                if (result.success) {
                    if (result.data.theme != null) {
                        this._themeService.setTheme(result.data.theme);
                    }
                }
            },
            error: (err: any) => {
                console.log(err);
            }
        });
    }

    langSelected(pItem: Filters) {
        this.langItemSelected = pItem;
        const data = this.OnSendDataPayload(1);
        const URL = `${environment.WebApiUrl + this.onSendURLParameter(1)}`;
        this._apiService.apiPostMethod(URL, data).subscribe({
            next: (result: ResponseData) => {
                if (result.success) {
                    this._translationService.setTranslate(pItem.value);
                    this.domainInfo.language = pItem.value;
                    localStorage.setItem("DomainCTS", JSON.stringify(this.domainInfo));
                    this._notificationService.success('GENERICS.ALERT_SUCESS', 'bg-success', 'animate__backInUp', 6000);
                } else {
                    this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
                }
            },
            error: (err: any) => {
                console.log(err);
                this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
            }
        });
    }

    setCookiesSession(isSmart: boolean) {
        if (isSmart) {
            this._cookieService.set("IdUser", this.userLogin.idUser.toString(), getSmartLoginTimeOut());
            //this._cookieService.set("isSmartLogin", 'true', getSmartLoginTimeOut());
            localStorage.setItem('isSmartLogin', 'true');
            this._cookieService.set('token', this.getTokenEncoded(), getSmartLoginTimeOut());
        } else {
            this._cookieService.set('token', this.getTokenEncoded());
            this._cookieService.set('IdUser', this.userLogin.idUser.toString());
        }
    }

    onSubmit() {
        const URL = `${environment.WebApiUrl + PutMethods.UPDATE_SMART_LOGIN}`;
        const data = {
            idUser: this.userLogin.idUser,
            smartSelection: this.f.isSmartLogin.value,
        }
        this._apiService.apiPostMethod(URL, data).subscribe({
            next: (result: ResponseData) => {
                if (result.success) {
                    this.getSmartLogin();
                    this._notificationService.success('GENERICS.ALERT_SUCESS', 'bg-success', 'animate__backInUp', 6000);
                }
            },
            error: (err: any) => {
                console.log(err);
            }
        });
    }

    getTokenEncoded() {
        return btoa(this.userLogin.idUser + ":" + this.userLogin.token + ":" + this.userLogin.adminType);
    }

    GeneralSearch() {
        this.generalSearchModal.show();
    }

    onSearch(value: string): void {
        if (value.trim() === '') {
            this.ticketData = this.ticketDataTemp.slice(0, 5)
        } else {
            this.ticketData = this.ticketDataTemp
        }
    }

    getTableInfo(pTicket: number) {
        this.isAccountSelected = pTicket;
        let date1 = new Date();
        let date2 = new Date();
        date2.setDate(date2.getDate() + 1);
        date1.setDate(date1.getDate() - 20);
        this.showTable = true;

        this.loading = true;
        const data = {
            idUser: this.userLogin.idUser,
            transactionType: 2,
            idSiteType: -1,
            startDate: transformMediumDate(date1),
            endDate: transformMediumDate(date2),
            User: this.userLogin.idUser
        }
        const URL = `${environment.WebApiUrl + GetMethods.GET_ALL_TICKET_INFO + '?idUser=' + data.idUser + '&transactionType=' + data.transactionType + '&startDate=' + data.startDate + '&endDate=' + data.endDate + '&IdSiteType=' + -1}`;

        this._apiService.apiGetMethod(URL).subscribe({
            next: (result: ResponseData) => {
                if (result.success) {
                    this.ticketData = result.data.slice(0, 5);
                    this.ticketDataTemp = result.data;
                } else {
                    this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
                }
                this.loading = false;
            },
            error: (err: any) => {
                this.loading = false;
                this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
                console.log(err);
            }
        });
    }

    logout() {
        this._apiService.logout();
    }

    TranslateText(text: string) {
        return this._translateService.instant(text);
    }

    itemSelected(item) {
        sessionStorage.setItem("TicketSelected", JSON.stringify(item));
        sessionStorage.setItem("IdSiteType", JSON.stringify(item.ticketInfo.idSiteType));
        const URL = `${environment.WebApiUrl + GetMethods.GET_OPEN_TICKET_STATUS + item.idTicket}`;
        this._apiService.apiGetMethod(URL).subscribe({
            next: (result: ResponseData) => {
                if (result.success) {
                    if (result.data.openTicketStatus == false) {
                        var url = '/dashboard/tickets';
                        location.assign(location.protocol + '//' + window.location.host + url);
                    }
                    else {
                        this.userActive = result.data.openTicketUser
                        this.getMessageInfo("EditedMessage", 3000);
                    }
                } else {
                    this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
                }
            },
            error: (err: any) => {
                this.loading = false;
                this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
                console.log(err);
            }
        });
    }

    OnSendDataPayload(key: number) {
        switch (key) {
            case 1: return {
                idUser: this.userLogin.idUser.toString(),
                lan: this.langItemSelected.value,
                idSite: this.domainInfo.idSite,
                encriptado: false,
                idSiteType: 3
            };
            default: return null;
        }
    }

    onSendURLParameter(key: number) {
        switch (key) {
            case 1: return PutMethods.UPDATE_LANGUAGE_BY_USER;
            default: return null;
        }
    }

    getModalInit() {
        this.generalSearchModal = new bootstrap.Modal(document.getElementById('generalSearchModal'), {
            keyboard: false
        })
    }

    getMessageInfo(message: string, time: number) {
        this.messageResult = message;
        setTimeout(() => {
            this.messageResult = '';
        }, time);
    }
}
