import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  private showLoadingSubject = new BehaviorSubject<boolean>(true);
  showLoading$ = this.showLoadingSubject.asObservable();

  constructor() { }

  toggleLoading() {
    const currentValue = this.showLoadingSubject.getValue();
    this.showLoadingSubject.next(!currentValue);
  }

  getShowLoading(){
    return this.showLoadingSubject;
  }
}
