<p-table #dt
    [value]="payloadData"
    [columns]="columsData"
    [scrollable]="true"
    scrollHeight="700px"
    dataKey="id"
    styleClass="p-datatable-striped"
    [rowHover]="true"
    [rows]="rowsSave"
    [rowsPerPageOptions]="[20, 40, 60]"
    [showCurrentPageReport]="true"
    [loading]="loading"
    [paginator]="true"
    responsiveLayout="stack"
    [tableStyle]="{'min-width': '80rem'}"
    currentPageReportTemplate="{{'Showing {first} to {last} of {totalRecords} entries' | translate}}"
    [filterDelay]="0"
    [globalFilterFields]="['ticketInfo.ticketNumber','ticketInfo.idPlayer', 'subject']"
    (onPage)="onRowsPerPageChange($event)">>

    <ng-template *ngIf="payloadData" pTemplate="caption">
        <div class="row g-2 my-2">
            <div class="col-sm-4 col-md-4 col-lg-4 col-xl-2">
                <input pInputText type="text" (input)="dt.filter($any($event.target) .value, 'ticketInfo.ticketNumber', 'contains')" placeholder="{{'Ticket №' | translate}}" class="p-column-filter w-100">
            </div>
            <div class="col-sm-4 col-md-4 col-lg-4 col-xl-2">
                <input pInputText type="text" (input)="dt.filter($any($event.target) .value, 'ticketInfo.idPlayer', 'contains')" placeholder="{{'Account' | translate}}" class="p-column-filter w-100">
            </div>
            <div class="col-sm-4 col-md-4 col-lg-4 col-xl-2">
                <input pInputText type="text" (input)="dt.filter($any($event.target) .value, 'ticketInfo.assignedUser', 'contains')" placeholder="{{'Assigned' | translate}}" class="p-column-filter w-100">
            </div>
            <div class="col-sm-6 col-md-6 col-lg-4 col-xl-2">
                <p-calendar [showClear]="true" placeholder="{{'Start Date' | translate}}" [(ngModel)]="date1" class="datePickTC"  dateFormat="d/m/yy" styleClass="w-100"></p-calendar>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-4 col-xl-2">
                <p-calendar placeholder="{{'End Date' | translate}}" [(ngModel)]="date2" class="datePickTC" (ngModelChange)="DateFilter()" dateFormat="d/m/yy" styleClass="w-100"></p-calendar>
            </div>
            <div class="col-sm-12 col-md-4 col-lg-4 col-xl-2 d-none d-lg-flex">
                <p-button (onClick)="hardRefresh()" styleClass="p-button-secondary mx-1" icon="feather icon-refresh-cw" ngbTooltip="{{'Refresh' | translate }}" label=""></p-button>

                <p-button (onClick)="getViewClosedTickets()" ngbTooltip="{{ !isViewClosedTickets ? TranslateText('View Closed Tickets') : TranslateText('Hide Closed Tickets')}}"  styleClass="p-button-secondary mx-1" icon="feather icon-{{ !isViewClosedTickets ? 'eye' : 'eye-off' }}" label=""></p-button>

                <p-button [disabled]="payloadData.length == 0" (onClick)="downloadModal()" styleClass="p-button-primary mx-1" icon="feather icon-download" ngbTooltip="{{ 'Download As' | translate }}" label=""></p-button>
            </div>
        </div>
        <div class="row g-2">
            <ng-container *ngIf="existsBrandSetup">
                <div class="col-sm-6 col-md-6 col-lg-4 col-xl-2">
                    <p-dropdown
                        placeholder="{{'Website' | translate }}"
                        styleClass="p-column-filter d-flex w-100"
                        [options]="allSiteTags"
                        (onChange)="dt.filter($event.value, 'site', 'equals')"
                        [filter]="true"
                        filterBy="description"
                        [showClear]="true">
                        <ng-template let-option pTemplate="item">
                            <span [class]="'customer-badge status-' + option.value">{{option.label | translate}}</span>
                        </ng-template>
                        <ng-template let-selectedItem pTemplate="selectedItem">
                            {{selectedItem.label | translate}}
                        </ng-template>
                    </p-dropdown>
                </div>
            </ng-container>
            <ng-container *ngIf="domainInfo.idSite == 0 && !existsBrandSetup">
                <div class="col-sm-6 col-md-6 col-lg-4 col-xl-2">
                    <p-dropdown
                        placeholder="{{'Website' | translate }}"
                        styleClass="p-column-filter d-flex w-100"
                        [options]="allSiteTags"
                        (onChange)="dt.filter($event.value, 'site', 'equals')"
                        [filter]="true"
                        filterBy="description"
                        [showClear]="true">
                        <ng-template let-option pTemplate="item">
                            <span [class]="'customer-badge status-' + option.value">{{option.label | translate}}</span>
                        </ng-template>
                        <ng-template let-selectedItem pTemplate="selectedItem">
                            {{selectedItem.label | translate}}
                        </ng-template>
                    </p-dropdown>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-4 col-xl-2">
                    <p-dropdown [options]="departmentList" (onChange)="dt.filter($event.value, 'departmentName', 'equals')" styleClass="p-column-filter d-flex w-100"
                        placeholder="{{'Department' | translate}}" [showClear]="true">
                        <ng-template let-option pTemplate="item">
                            <span [class]="'customer-badge status-' + option.value">{{option.label | translate}}</span>
                        </ng-template>
                        <ng-template let-selectedItem pTemplate="selectedItem">
                            {{selectedItem.label | translate}}
                        </ng-template>
                    </p-dropdown>
                </div>
            </ng-container>
            <div class="col-sm-6 col-md-6 col-lg-4 col-xl-2">
                <p-dropdown [options]="subjectList" (onChange)="dt.filter($event.value, 'subject', 'equals')" styleClass="p-column-filter d-flex w-100"
                placeholder="{{'Subject' | translate}}" [showClear]="true">
                <ng-template let-option pTemplate="item">
                    <span [class]="'customer-badge status-' + option.value">{{option.label | translate}}</span>
                </ng-template>
                <ng-template let-selectedItem pTemplate="selectedItem">
                    {{selectedItem.label | translate}}
                </ng-template>
            </p-dropdown>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-4 col-xl-2">
                <p-dropdown [options]="statusList" (onChange)="dt.filter($event.value, 'status', 'equals')" styleClass="p-column-filter d-flex w-100" placeholder="{{'Status' | translate}}"
                [showClear]="true">
                    <ng-template let-option pTemplate="item">
                        <span [class]="'customer-badge status-' + option.value">{{option.label | translate}}</span>
                    </ng-template>
                    <ng-template let-selectedItem pTemplate="selectedItem">
                        {{selectedItem.label | translate}}
                    </ng-template>
                </p-dropdown>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-2">
                <p-dropdown [options]="relevanceList" (onChange)="dt.filter($event.value, 'relevance', 'equals')"
                    styleClass="p-column-filter w-100" placeholder="{{'Relevance' | translate}}" [showClear]="true">
                    <ng-template let-option pTemplate="item">
                        <span [class]="'customer-badge status-' + option.value">{{ option.description | translate }}</span>
                    </ng-template>
                    <ng-template let-selectedItem pTemplate="selectedItem">
                        {{selectedItem.description | translate}}
                    </ng-template>
                </p-dropdown>
            </div>
            <div class="col-5 col-sm-6 col-md-3 col-lg-4 col-xl-2">
                <div pSortableColumn="date" class="d-flex justify-content-start align-items-center p-2">
                    {{'Filter' | translate}}
                    <p-sortIcon field="date"></p-sortIcon>
                    <p-columnFilter type="date" field="date" display="menu" [matchModeOptions]="matchModeOptions" class="ml-auto"></p-columnFilter>
                </div>
            </div>
            <div class="col-7 col-sm-6 col-md-3 col-lg-4 col-xl-2 d-flex d-lg-none">
                <p-button (onClick)="refresh()"  styleClass="p-button-secondary mx-1" icon="feather icon-refresh-cw" pTooltip="{{'Refresh' | translate}}" label=""></p-button>

                <p-button (onClick)="getViewClosedTickets()" pTooltip="{{ !isViewClosedTickets? 'View Closed Tickets': 'Closed Tickets' | translate }}"  styleClass="p-button-secondary mx-1" icon="feather icon-{{ !isViewClosedTickets ? 'eye' : 'eye-off' }}" label=""></p-button>

                <p-button [disabled]="payloadData.length == 0" (onClick)="downloadModal()" styleClass="p-button-primary mx-1" icon="feather icon-download" pTooltip="{{ 'Download As' | translate }}" label=""></p-button>
            </div>
        </div>
    </ng-template>

    <ng-template pTemplate="header" let-columns>
        <tr>
            <th *ngFor="let col of columns">
                {{ col.header | translate }}
            </th>
        </tr>
    </ng-template>

    <ng-template pTemplate="body" let-payloadData>
        <tr class="p-selectable-row">
            <td>
                <a class="p-1 text-decoration-none text-secondary fs-6" pTooltip="{{'Full Message' | translate}}" (click)="GoFullMessage(payloadData)">
                    <i class="feather icon-eye"></i>
                    <span *ngIf="payloadData.countNotification > 0" class="badge bg-danger rounded-pill custom-badge">{{payloadData.countNotification}}</span>
                </a>
                <a class="p-1 text-decoration-none text-secondary fs-6" pTooltip="Release Ticket" *ngIf="userLogin.idUserType == 1 || userLogin.idUserType == 3 || userLogin.idUserType == 5 || userLogin.releaseTicket" (click)="ReleaseTicket(payloadData.idTicket)"> <i class="feather icon-refresh-cw"></i> </a>
                <span [ngClass]="getStatusType(payloadData.status)">{{payloadData.ticketInfo.ticketNumber}} </span>
            </td>
            <td>
                <span class="p-column-title">Account</span>
                <span [ngClass]="getStatusType(payloadData.status)">{{payloadData.ticketInfo.idPlayer}}</span>
            </td>
            <td *ngIf="domainInfo.idSite != 992">
                <span class="p-column-title">Website</span>
                <span [ngClass]="getStatusType(payloadData.status)">{{payloadData.site | translate}}</span>
            </td>
            <td>
                <span class="p-column-title">Department</span>
                <span *ngIf="payloadData.ticketInfo.department.length > 0" [ngClass]="getStatusType(payloadData.status)"> {{payloadData.ticketInfo.department[0].departmentName | translate}} </span>
            </td>
            <td *ngIf="domainInfo.idSite == 992">
                <span class="p-column-title">Subject</span>
                <span [ngClass]="getStatusType(payloadData.status)">{{payloadData.subject | translate}}</span>
            </td>
            <td>
                <span class="p-column-title">Field</span>
                <span [ngClass]="getStatusType(payloadData.status)">{{payloadData.ticketInfo.subjectField | translate}}</span>
            </td>

            <td>
                <span class="p-column-title">Priority</span>
                <span [ngClass]="getStatusType(payloadData.status)">{{payloadData.relevance | translate}}</span>
            </td>
            <td *ngIf="domainInfo.idSite == 992">
                <span class="p-column-title">Status</span>
                <span [ngClass]="getStatusType(payloadData.status)">{{payloadData.status | translate}}</span>
            </td>
            <td>
                <span class="p-column-title">Assigned</span>
                <span [ngClass]="getStatusType(payloadData.status)">{{payloadData.ticketInfo.assignedUser | translate}}</span>
            </td>

            <td>
                <span class="p-column-title">Date</span>
                <span [ngClass]="getStatusType(payloadData.status)">{{payloadData.ticketInfo.dateTransaction  | date: 'MM/dd/yyyy' }}</span>
            </td>

        </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage">
        <tr>
            <td style="padding: 200px; text-align: center;" colspan="12">
                <span *ngIf="!showHardReloadBtn"> {{ 'GENERICS.NO_TICKET_FOUND' | translate }} </span>
                <span *ngIf="showHardReloadBtn"> {{'GENERICS.UPDATED_ERROR' | translate}} <a class="btn btn-danger" (click)="hardRefresh()"> <i class="feather icon-refresh-cw"></i> {{ 'Refresh' | translate }}</a> </span>
            </td>
        </tr>
    </ng-template>
</p-table>
