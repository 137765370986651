import { Component, OnInit } from '@angular/core';
import { OnlineStatusService, OnlineStatusType } from 'ngx-online-status';
import { NotificationService } from './services/notification.service';
import { ConfigByDomain, ResponseData } from '@models/models';
import { environment } from '../environments/environment.development';
import { ApiService } from '@services/api.service';
import { GetMethods, Methods, PutMethods, getThemeFilter } from '@methods/methods';
import { ThemeService } from '@services/theme.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Welcome | Customer Ticke Service';
  location: string = window.location.href;
  ConfigByDomain: ConfigByDomain = new ConfigByDomain();
  themeColor: string = '#1D4A49';
  status: OnlineStatusType;
  themeSelected: any = '';
  localTheme: string = '';
  lang: string = ''

  constructor(private onlineStatusService: OnlineStatusService, private _notificationService: NotificationService, private _apiService: ApiService, private _themeService: ThemeService ) {
    this.onlineStatusService.status.subscribe((status: OnlineStatusType) => {
      this.status = status;
      if (this.status == 0) {
        this._notificationService.error('GENERICS.CONECTION_LOST', 'bg-dark', 'animate__backInUp', 6000);
      } else {
        this._notificationService.success('GENERICS.CONNECTION_SUCCESS', 'bg-success', 'animate__backInUp', 6000);
      }
    });
  }

  ngOnInit(): void {
    this.lang = localStorage.getItem('lang')!;
    this.localTheme = this._themeService.getThemeSelected();
    const dataselected = getThemeFilter(this.localTheme);
    this._themeService.setTheme(dataselected);
    this.GetConfigurationByDomain();
    console.log('2.0.1');

  }

  GetConfigurationByDomain(){
    this.ConfigByDomain.domain = this.location;


    if (this.ConfigByDomain.domain != environment.AdminUrl){

      if (this.ConfigByDomain.domain.includes('home')){
        let split = this.ConfigByDomain.domain.split('home')
        this.ConfigByDomain.domain = split[0] + "home";
      }

      if (this.ConfigByDomain.domain.includes('agentHome')){
        let split = this.ConfigByDomain.domain.split('agentHome')
        this.ConfigByDomain.domain = split[0] + "agentHome";
      }

      if (this.ConfigByDomain.domain.includes('eposHome')){
        let split = this.ConfigByDomain.domain.split('eposHome')
        this.ConfigByDomain.domain = split[0] + "eposHome";
      }

      if (this.ConfigByDomain.domain.includes('eposAdmin')){
        let split = this.ConfigByDomain.domain.split('eposAdmin')
        this.ConfigByDomain.domain = split[0] + "eposAdmin";
      }

      if (!this.ConfigByDomain.domain.includes('agentHome') && !this.ConfigByDomain.domain.includes('home')
          && !this.ConfigByDomain.domain.includes('eposHome') && !this.ConfigByDomain.domain.includes('eposAdmin')){
        //this.ConfigByDomain.domain = "ctsadmin.com";
        this.ConfigByDomain.domain = window.location.host;

      }

      const URL = `${environment.WebApiUrl + GetMethods.GET_CONFIG_BY_DOMAIN + '?domain=' + this.ConfigByDomain.domain}`;

      this._apiService.apiGetMethod(URL).subscribe({
        next: (result: ResponseData) => {
          if(result.success){
            if(this.lang != null) {
                localStorage.setItem("lang", this.lang);
            }else{
                localStorage.setItem("lang", result.data.language != null ? result.data.language: 'en');
            }
            this.localTheme = this._themeService.getThemeSelected();
            const dataselected = getThemeFilter(this.localTheme != null ? this.localTheme: result.data.Theme);
            this._themeService.setTheme(dataselected);
            localStorage.setItem("DomainCTS", JSON.stringify(result.data));
            console.log("CONFIG BY DOMAIN", result.data);
          }
        },
        error: (err: any) => {
          console.log(err);
        }
      });
    }
  }
}
