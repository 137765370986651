<div class="toolbar-header fixed-top" [ngClass]="{ 'expanded': isExpanded, 'closed' : !isExpanded }">
    <div class="row g-0">
        <div class="col-10 justify-content-between align-items-center">
            <button class="btn btn-primary m-2" aria-label="Open Menu" type="button" data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasExample" aria-controls="offcanvasExample" *ngIf="Media.IsMobile">
                <i class="fas fa-bars"></i>
            </button>
            <nav class="navbar navbar-icon-top navbar-expand-lg navbar-dark"
                *ngIf="!Media.IsMobile">
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul *ngIf="navigation" class="navbar-nav ">
                        <li *ngFor="let item of navigation; let i = index" class="nav-item dropdown">
                            <ng-container *ngIf="item.hasPermission">
                                <a *ngIf="item.showInToolbar && item.SubMenu.length == 0" [routerLinkActive]="['active']" class="nav-link"
                                    [routerLink]="item.routerLink">
                                    <i class="{{item.iconClass}}">
                                        <span *ngIf="item.isNew" class="badge badge-info bg-danger text-sm" style="font-size: 9px;">New</span>
                                    </i>
                                    {{ item.translate | translate }}
                                </a>
                                <div *ngIf="item.SubMenu.length > 0">
                                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown"
                                        aria-haspopup="true" aria-expanded="false">
                                        <i class="{{item.iconClass}}">
                                            <span *ngIf="item.isNew" class="badge badge-info bg-danger text-sm" style="font-size: 9px;">New</span>
                                        </i>
                                        <p>
                                            {{ item.translate | translate }} <i class="fas fa-arrow-down"></i>
                                        </p>
                                    </a>
                                    <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                                        <a *ngFor="let val of item.SubMenu" class="dropdown-item" [routerLink]="val.routerLink"><span> </span> <i
                                                class='{{val.iconClass}} nav_icon ms-2' style="margin-right: 6px;"></i>
                                            <span class="nav_name">{{ val.translate | translate }}<span *ngIf="val.isNew"
                                                    class="badge badge-info bg-danger text-sm ms-2"
                                                    style="font-size: 9px; z-index: 100;">New</span></span> </a>
                                    </div>
                                </div>
                            </ng-container>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
        <div class="col-2 d-flex justify-content-end align-items-center button-logout">

            <a class="btn text-light-nav dropdown-toggle" aria-label="Search Modal" role="button" (click)="GeneralSearch()">
                <i class="feather icon-search fs-4 text-light"></i>
            </a>

            <ul class="navbar-nav">
                <li class="nav-item dropdown">
                    <!-- <a class="btn text-light-nav dropdown-toggle" aria-label="button" href="#" role="button"
                        id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                        <i class="feather icon-bell fs-4"></i>
                        <span style="top:10px; right: 10px;" class="position-absolute start-75 translate-middle p-1 bg-danger border border-light rounded-circle">
                            <span class="visually-hidden">New alerts</span>
                        </span>
                    </a> -->
                    <div class="dropdown-menu text-small shadow dropdown-right p-3" aria-labelledby="navbarDropdownMenuLink-4">
                        <div class="notification d-flex justify-content-between">
                            <h6 class="fw-bold d-inline">Notifications</h6>
                            <button class="btn text-dark fw-bold p-0" type="button" style="font-size: 12px;">Show All</button>
                        </div>
                        <div class="row mt-3 g-0">
                            <div class="col-2">
                                <span class="badge bg-secondary"><i class="fas fa-ticket-alt"></i></span>
                            </div>
                            <div class="col-7">
                                <span class="text-light-nav" style="font-size: 13px;">New help ticket - Ticket</span>
                            </div>
                            <div class="col-3 text-end">
                                <span class="badge rounded-pill bg-secondary">#67897</span>
                            </div>
                        </div>
                        <div class="row mt-3 g-0">
                            <div class="col-2">
                                <span class="badge bg-secondary"><i class="fas fa-ticket-alt"></i></span>
                            </div>
                            <div class="col-7">
                                <span class="text-light-nav" style="font-size: 13px;">New help ticket - Ticket</span>
                            </div>
                            <div class="col-3 text-end">
                                <span class="badge rounded-pill bg-secondary">#67897</span>
                            </div>
                        </div>
                        <div class="row mt-3 g-0">
                            <div class="col-2">
                                <span class="badge bg-secondary"><i class="fas fa-ticket-alt"></i></span>
                            </div>
                            <div class="col-7">
                                <span class="text-light-nav" style="font-size: 13px;">New help ticket - Ticket</span>
                            </div>
                            <div class="col-3 text-end">
                                <span class="badge rounded-pill bg-secondary">#67897</span>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>

            <div class="dropdown">
                <a class="btn dropdown-toggle" aria-label="logout" data-bs-auto-close="outside" type="button" id="dropdownlanguageSelector" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="feather icon-globe text-light fs-4"></i>
                </a>
                <ul class="dropdown-menu dropdown-menu-bg p-0" aria-labelledby="dropdownlanguageSelector">
                    <li *ngFor="let item of langFilter">
                        <a (click)="langSelected(item)" class="dropdown-item p-3"> <span class="p-2 d-inline-block">{{item.label}}</span></a>
                    </li>
                </ul>
            </div>

            <div class="dropdown">
                <a class="btn dropdown-toggle" aria-label="logout" data-bs-auto-close="outside" type="button" id="dropdownMenuButton1" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="fas fa-user-circle text-light fs-4"></i>
                </a>
                <ul class="dropdown-menu dropdown-menu-bg p-0" aria-labelledby="dropdownMenuButton1">
                    <ng-container *ngIf="userLogin">
                        <li>
                            <a class="dropdown-item p-3" (click)="logout()" href="#"> <img class="rounded-circle" width="30" height="30" src="assets/images/userProfile.jpg" alt="Profile Icon"> <span class="p-2 d-inline-block">{{userLogin.name}} <i class="feather icon-log-out nav_icon ms-1 me-1"></i></span></a>
                        </li>
                        <li><a class="dropdown-item p-3" [routerLink]="'/configuration'"><i class="feather icon-settings me-2 fs-4"></i> {{TranslateText('GENERICS.CONFIGURATION')}}</a></li>
                        <li>
                            <a class="dropdown-item p-3" href="#" [routerLink]="'/dashboard/twofactor'"><img class="rounded-circle me-1" width="30" height="30" src="assets/images/2falogo-v2.png" alt="Two Factor Icon"> <span class="ms-1">{{ 'USER.NAVBAR.TWO_FACTOR' | translate}}</span> </a>
                        </li>
                         <li>
                            <span class="dropdown-item p-3 d-flex"><img class="rounded-circle me-1" width="30" height="30" src="assets/images/smartLogin.png" alt="Smart Login">
                            <form [formGroup]="smartLoginForm" (ngSubmit)="onSubmit()">
                                <p-inputSwitch (click)="onSubmit()" formControlName="isSmartLogin"></p-inputSwitch>
                            </form>
                            <span class="ms-2">{{ 'USER.NAVBAR.SMART_LOGIN' | translate}}</span> </span>
                        </li>
                    </ng-container>
                </ul>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="generalSearchModal" tabindex="-1" aria-labelledby="generalSearchModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog modal-dialog-centered modal-search">
        <div class="modal-content">
            <div class="modal-header px-3 py-2">
                <h5 class="modal-title fw-bold w-100 text-center" id="generalSearchModalLabel">{{ 'General Search' | translate}}</h5>
                <a type="button" class="modal-title px-0" data-bs-dismiss="modal" aria-label="Close"> <i class="fas fa-times"></i> </a>
            </div>
            <div class="modal-body pt-0">

                <div class="d-grid row g-2 justify-content-center mb-3">
                    <button class="btn btn-secondary btn-sm fw-bold text-uppercase" (click)="getTableInfo(1)">{{'Search By Ticket Number' | translate}}</button>
                    <button class="btn btn-secondary btn-sm fw-bold text-uppercase" (click)="getTableInfo(2)">{{'Search By Account' | translate}}</button>
                </div>

                <div *ngIf="messageResult != ''" class="alert alert-danger mt-3" role="alert">
                    {{messageResult | translate }} {{userActive}}.
                </div>

                <p-table *ngIf="showTable" #dt [value]="ticketData" [tableStyle]="{ 'min-width': 'auto' }" styleClass="p-datatable-customers" [globalFilterFields]="['ticketInfo.ticketNumber', 'ticketInfo.idPlayer']">
                    <ng-template pTemplate="caption">
                        <div class="table-header d-flex justify-content-center align-items-center">

                            <span class="p-input-icon-left">
                                <i class="feather icon-search"></i>
                                <input pInputText type="text" (input)="onSearch($any($event.target) .value)" (input)="dt.filterGlobal($any($event.target) .value,'contains')" placeholder="{{ 'Search' | translate }}" />
                            </span>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="header">
                        <tr>
                            <th style="font-weight: bold;">
                                <span *ngIf="isAccountSelected == 1 "> {{'Ticket' | translate}} </span>
                                <span *ngIf="isAccountSelected == 2 "> {{'Account' | translate}} </span>
                            </th>
                            <th style="font-weight: 300;">{{'View' | translate }}</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-product>
                        <tr>
                            <td>
                                <span
                                    *ngIf="isAccountSelected == 1 "
                                    class="fw-bold" [ngClass]="{'text-in-progress': product.status == 'In-Progress', 'text-open': product.status == 'Open', 'text-resolved' : product.status == 'Resolved' }">
                                    {{ product.ticketInfo.ticketNumber }}
                                </span>
                                    <small class="small-color" *ngIf="isAccountSelected == 1 ">
                                        {{ product.ticketInfo.idPlayer }}
                                    </small>
                                <span
                                    *ngIf="isAccountSelected == 2 "
                                    class="fw-bold" [ngClass]="{'text-in-progress': product.status == 'In-Progress', 'text-open': product.status == 'Open', 'text-resolved' : product.status == 'Resolved' }">
                                    {{ product.ticketInfo.idPlayer }}
                                </span>
                                    <small class="small-color" *ngIf="isAccountSelected == 2 ">
                                        {{ product.ticketInfo.ticketNumber }}
                                    </small>
                            </td>
                            <td>
                                <button class="btn btn-icon" (click)="itemSelected(product)">
                                    <i class="feather icon-eye"></i>
                                </button>
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="8">No <span *ngIf="isAccountSelected == 1">ticket</span> <span *ngIf="isAccountSelected == 2">account</span>   found.</td>
                        </tr>
                    </ng-template>
                </p-table>

            </div>
        </div>
    </div>
</div>
