import { Component, ViewChild } from '@angular/core';
import { GetMethods, Methods, PutMethods, getStatusType, responseError } from '@methods/methods';
import { User } from '@models/auth-model';
import { OpenTicketDTO, ResponseData } from '@models/models';
import { ApiService } from '@services/api.service';
import { NotificationService } from '@services/notification.service';
import { ThemeService } from '@services/theme.service';
import { PrimeNGConfig } from 'primeng/api';
import { Table } from 'primeng/table';
import { environment } from 'src/environments/environment';

interface Column {
  field: string;
  header: string;
  customExportHeader?: string;
}
@Component({
  selector: 'app-ticker',
  templateUrl: './ticker.component.html'
})
export class TickerComponent {
  payloadData!: any;
  ticketSelected: any;
  loading: boolean = true;
  userLogin: User;
  cols!: Column[];
  showHardReloadBtn: boolean = false;
  showMessageInfo: boolean = false;
  subjectList: any = [];
  departmentList: any = [];
  relevanceList: any = [];
  statusList: any = [];
  idSiteType: number = 0;
  isDarkTheme: boolean;

  @ViewChild('dt') table: Table;

  constructor(private _apiService: ApiService, private primengConfig: PrimeNGConfig, private _notificationService: NotificationService, private _themeService: ThemeService) { }

  ngOnInit() {

    this.cols = [
      { field: 'ticket', header: 'Ticket', customExportHeader: 'Product Code' },
      { field: 'account', header: 'Account' },
      { field: 'subject', header: 'Subject' },
      { field: 'website', header: 'Website' },
      { field: 'date', header: 'Date' },
      { field: 'priority', header: 'Priority' },
      { field: 'assigned', header: 'Assigned To' },
      { field: 'status', header: 'Status' },
      { field: 'timer', header: 'Timer' },
    ];

    this.primengConfig.ripple = true;
    this.userLogin = JSON.parse(localStorage.getItem('UserAccessCTS')!);
    this.ticketSelected = JSON.parse(sessionStorage.getItem('TicketSelected')!);
    this.idSiteType = JSON.parse(sessionStorage.getItem('IdSiteType')!);
    this.isDarkTheme = this._themeService.getThemeSelected() == 'theme-default-dark' ? true : false;


    if(this.userLogin){
        this.getGlobalInfo(0);
      if(this.ticketSelected != null && this.ticketSelected != 'null'){
        this.getGlobalInfo(1);
        this.getGlobalInfo(2);
        this.getGlobalInfo(3);
        this.getGlobalInfo(4);
        this.showMessageInfo = true;
      }else{
        this.showMessageInfo = false;
      }
      this.UpdateOpenTicketStatus(this.showMessageInfo);
    }else{
        this.loading = false;
    }
  }

  getGlobalInfo(key:number) {
    const data = this.onPayloadData(key);
    const URL = `${environment.WebApiUrl + this.returnUrlParameter(key)}`;

    this._apiService.apiGetMethod(URL).subscribe({
      next: (result: ResponseData) => {
        if(result.success){
          if(key == 0) {
            this.payloadData = result.data;
          }
          if(key == 1){
            result.data.forEach(element => {
              element.label = element.name;
              element.name = element.name;
            });
            this.subjectList = result.data;
          }
          if(key == 2) {
            this.departmentList = result.data;
          }
          if(key == 3){
            this.relevanceList = result.data;
          }
          if(key == 4){
            this.statusList = result.data;
          }

        }
        this.loading = false;
      },
      error: (err: any) => {
        this.loading = false;
        this.showHardReloadBtn = true;
        console.log(err);
        const messageTypeErrorText = responseError(err.status);
        this._notificationService.error(messageTypeErrorText, 'bg-dark', 'animate__backInUp', 6000);
      }
    });
  }

  onStepChange(returnToTicket: boolean){
    sessionStorage.setItem("TicketSelected", 'null');
    sessionStorage.setItem("IdSiteType", 'null');
    this.showMessageInfo = returnToTicket;
    this.getGlobalInfo(0);
    this.UpdateOpenTicketStatus(this.showMessageInfo);
  }

  UpdateOpenTicketStatus(showMessageInfo: boolean) {
    let obj: OpenTicketDTO = new OpenTicketDTO();
    obj.IdTicket = this.showMessageInfo ? this.ticketSelected.IdTicket : 0;
    obj.openStatus = showMessageInfo;
    obj.User = this.userLogin.idUser;
    const URL = `${environment.WebApiUrl + PutMethods.UPDATE_TICKET_STATUS}`;
    this._apiService.apiPostMethod(URL, obj).subscribe({
      next: (result: ResponseData) => {},
      error: (err: any) => {
        console.log(err);
      }
    });
  }

  getStatusType(status:string){
    return getStatusType(status, this.isDarkTheme);
  }

  returnUrlParameter(key: number){
    switch (key) {
      case 0: return GetMethods.GET_ALL_TICKET_INFO + '?idUser=' + this.userLogin.idUser + '&transactionType=' + 2 + '&IdSiteType=' + -1;
      case 1: return GetMethods.GET_ALL_SUBJECTS;
      case 2: return GetMethods.GET_DEPARTMENT_LIST;
      case 3: return GetMethods.GET_RELEVANCE_LIST;
      case 4: return GetMethods.GET_STATUS_LIST;
      default: return null;
    }
  }

  onPayloadData(key:number){
    switch (key) {
      case 0: return {
        idUser: this.userLogin.idUser,
        transactionType: 2,
        idSiteType: -1,
      }
      case 1:
      case 2:
      case 3:
      case 4: return {}
      default: return null;
    }
  }

  hardRefresh(){
    location.reload();
  }

}

